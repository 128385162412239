import React, { useEffect, useState } from "react";
import Layout from "../components/Layout/Layout";
import DashboardLayout from "./components/DashboardLayout";
import { useSelector } from "react-redux";
import "./Wallet.css";
import axios from "axios";
import { message } from "antd";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import HistoryIcon from "@mui/icons-material/History";
import getUserData from "../utils/userDataService";

const Wallet = () => {
  const { user } = useSelector((state) => state.user);
  const [tab, setTab] = useState(0);
  const [form, setForm] = useState(null);
  const [payments, setPayments] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [orderId, setOrderId] = useState(null);
  const [balance, setBalance] = useState("");

  useEffect(() => {
    getUserData(null, null, setBalance);
  }, []);

  const generateOrderId = () => {
    const numbers = "01234567"; // 8 numbers
    const randomNumbers = Array.from({ length: 7 }, () =>
      numbers.charAt(Math.floor(Math.random() * numbers.length))
    );
    const now = new Date();
    const day = String(now.getDate()).padStart(2, "0");
    const month = String(now.getMonth() + 1).padStart(2, "0"); // getMonth() is 0-indexed
    const year = String(now.getFullYear()).slice(2); // last two digits of the year
    const seconds = String(now.getSeconds()).padStart(2, "0");
    const orderId = `${year}${month}${day}${seconds}${randomNumbers.join("")}`;
    setOrderId(orderId);
  };

  useEffect(() => {
    generateOrderId();
  }, []);

  async function handlePayOnline() {
    if (form?.amount < 1) {
      return message.error("Enter amount greater than 20");
    }
    try {
      setLoading(true);
      const paymentObject = {
        order_id: orderId,
        txn_amount: form?.amount,
        txn_note: "Wallet Topup Note",
        product_name: "Wallet Topup",
        customer_name: user?.fname,
        customer_email: user?.email,
        customer_mobile: user?.mobile,
        callback_url: `https://arataofficialstore.in/api/payment/status?orderId=${orderId}`,
      };

      const response = await axios.post(
        "/api/payment/addmoney",
        paymentObject,
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      );
      if (response.data.success && response.data.data.status) {
        window.location.href = response.data.data.results.payment_url;
        setLoading(false);
      } else {
        console.log(response.data.message);
        message.error(response.data.message);
        setLoading(false);
      }
    } catch (error) {
      console.error("Error initiating payment:", error);
      setLoading(false);
    }
  }

  async function getUserPayments() {
    try {
      const res = await axios.post("/api/payment/get-user-payments", {
        email: user?.email,
      });
      if (res.data.success) {
        setPayments(res.data.data);
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  }

  function handleChange(e) {
    const { name, value } = e.target;
    setForm({ ...form, [e.target.name]: e.target.value });
    if (name === "amount") {
      if (value < 50) {
        setError(true);
      } else {
        setError(false);
      }
    }
  }

  useEffect(() => {
    if (user?.email) {
      setForm((prev) => ({
        ...prev,
        email: user?.email,
        mobile: user?.mobile,
      }));
      getUserPayments();
    }
  }, [user]);

  return (
    <Layout>
      <DashboardLayout>
        <div className="wallet-dash">
          <div className="wallet-dash-card w-100 wallet-dash-card-one">
            Balance: {balance}
          </div>
          <div className="wallet-dash-card" onClick={() => setTab(1)}>
            <AddCircleIcon className="icon me-2" />
            Add Money
          </div>
          <div className="wallet-dash-card" onClick={() => setTab(0)}>
            <HistoryIcon className="icon me-2" />
            Transaction History
          </div>
        </div>

        {/* USER HISTORY */}
        {tab === 0 && (
          <table className="table table-bordered">
            <thead>
              <tr>
                <th>Sr No</th>
                <th>Amount</th>
                <th>OrderId</th>
                <th>Date</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {payments &&
                payments.map((item, index) => {
                  return (
                    <tr>
                      <td>{index + 1}</td>
                      <td>{item?.amount}</td>
                      <td>{item?.orderId}</td>
                      <td>
                        {new Date(item?.createdAt).toLocaleString("default", {
                          day: "numeric",
                          month: "long",
                          year: "numeric",
                          hour: "numeric",
                          minute: "numeric",
                          second: "numeric",
                        })}
                      </td>
                      <td
                        className={
                          item?.status === "pending"
                            ? "text-warning"
                            : item?.status === "approved"
                            ? "text-success"
                            : item?.status === "rejected"
                            ? "text-danger"
                            : ""
                        }
                      >
                        {item?.status}
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        )}

        {/* BARCODE  */}
        {tab === 1 && (
          <div className="add-money w-100">
            <div className="row w-100">
              <div className="col-12 col-sm-6 col-md-12 col-lg-6">
                <div className="form-fields">
                  <label className="text-white" htmlFor="">
                    Amount
                  </label>
                  <input
                    name="amount"
                    value={form?.amount}
                    onChange={handleChange}
                    className="form-control"
                    placeholder="Enter Amount"
                    type="text"
                  />
                  {error && form?.amount < 20 && (
                    <span className="text-danger">
                      Enter amount greater or equal to 20rs
                    </span>
                  )}
                </div>
                <button
                  onClick={handlePayOnline}
                  className="add-to-cart-btn w-100"
                >
                  Pay Online
                </button>
              </div>
            </div>
          </div>
        )}
      </DashboardLayout>
    </Layout>
  );
};

export default Wallet;
