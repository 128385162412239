const IMAGES = {
  banner1: require("../img/home/ban1.jpeg"),
  banner2: require("../img/home/ban2.jpeg"),
  phonepe: require("../img/phonepe.png"),
  paytm: require("../img/paytm.png"),
  gpay: require("../img/gpay.png"),
  upi: require("../img/upi.webp"),
};

export default IMAGES;
