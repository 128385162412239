import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import EmailIcon from "@mui/icons-material/Email";
import InstagramIcon from "@mui/icons-material/Instagram";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import { useSelector } from "react-redux";
import FacebookSharpIcon from "@mui/icons-material/FacebookSharp";
import HelpCenterIcon from "@mui/icons-material/HelpCenter";
import FeedbackIcon from "@mui/icons-material/Feedback";
import "../Footer/Footer.css";
import IMAGES from "../../img/image";

const Footer = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { user } = useSelector((state) => state.user);
  return (
    <React.Fragment>
      <div className="container-fluid footer-container">
        <div className="wa-icon-cont">
          <Link target="_blank" to="https://wa.me/918592831102">
            <WhatsAppIcon className="icon" />
          </Link>
        </div>
        <div className="row">
          <div className="col-12 col-sm-12 col-md-3 col-lg-3 mb-4">
            <div className="footer-logo">
              <b>
                ARATA<span className="ffca00">OFFICIAL</span>
              </b>
            </div>
            <span>
              <small>
                Welcome to ARATA Official! Get in-game currencies for Mobile
                Legends, BGMI, PUBG, Genshin Impact, and more with fast,
                reliable service and great customer support.
              </small>
            </span>
            <p className="m-0 mt-3 fw-bold">SUPPORT</p>
            <span>+91 8592831102</span>
            <hr />
            <div className="social">
              <Link
                target="_blank"
                to="https://www.instagram.com/arataofficialstore"
              >
                <InstagramIcon className="icon" />
              </Link>
              <Link target="_blank" to="https://wa.me/918592831102">
                <WhatsAppIcon className="icon" />
              </Link>
              <Link to="mailto:arataofficialstore@gmail.com">
                <EmailIcon className="icon" />
              </Link>
            </div>
          </div>
          <div className="px-lg-5 quick-links col-6 col-sm-6 col-md-3 col-lg-3 mb-4">
            <h6>Quick Links</h6>
            <hr />
            <ul>
              <li>
                <Link to="/">Home</Link>
              </li>
              {!user && (
                <>
                  <li>
                    <Link to="/login">Login</Link>
                  </li>
                  <li>
                    <Link to="/register">Register</Link>
                  </li>
                </>
              )}
              {user && (
                <>
                  <li>
                    <Link to="/wallet">Wallet</Link>
                  </li>
                  <li>
                    <Link to="/orders">Orders</Link>
                  </li>
                  <li>
                    <Link to="/user-dashboard">Dashboard</Link>
                  </li>
                </>
              )}
              <li>
                <Link to="/support">Customer Support</Link>
              </li>
            </ul>
          </div>
          <div className="quick-links col-6 col-sm-6 col-md-3 col-lg-3 mb-4">
            <h6>Important Pages</h6>
            <hr />
            <ul>
              <li>
                <Link to="/privacy-policy">Privacy Policy</Link>
              </li>
              <li>
                <Link to="/terms">Terms & Conditions</Link>
              </li>
              <li>
                <Link to="/refund-policy">Refund Policy</Link>
              </li>
            </ul>
          </div>
          <div className="quick-links col-12 col-sm-12 col-md-3 col-lg-3 mb-4">
            <h6>Payment Modes</h6>
            <hr />
            <div className="payment-channels">
              <img src={IMAGES.phonepe} alt="" />
              <img src={IMAGES.gpay} alt="" />
              <img src={IMAGES.upi} alt="" />
            </div>
          </div>
          <hr />
          <span className="">
            <small>
              All Rights Reserved © 2024 | ARATA STORE |{" "}
              <br className="d-block d-md-none d-lg-none" /> Website Developed
              by{" "}
              <Link
                style={{
                  color: `#eaf518`,
                  fontWeight: "600",
                  textDecoration: "underline",
                }}
                target="_blank"
                to="https://aashirdigital.com"
              >
                ~aashirdigital
              </Link>
            </small>
          </span>
        </div>
      </div>
      {/* <StickyFooter /> */}
    </React.Fragment>
  );
};

export default Footer;
